.c-navbar {
	@include u-text-furniture;
	background: $navbar-background;
	border-top: $navbar-border;
	border-bottom: $navbar-border;
	line-height: $navbar-line-height;
	padding: $navbar-padding;
}

.c-navbar--with-submit-button {
	padding-bottom: spacing(24);

	@include media-query('sm') {
		padding-bottom: spacing(16);
	}
}

.c-navbar__container {
	@include u-container;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.c-navbar__logo {
	background-color: transparent;
	display: inline-block;
	padding: 0;

	> a,
	> a > img {
		display: block;
	}
}

.c-navbar__content {
	flex: 0 1 auto;
	display: flex;
}

.c-navbar__nav {
	@include u-list-reset;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: $navbar-item-spacing;
}

.c-navbar__item {
	flex: 0 0 auto;
}

.c-navbar__link {
	display: block;
	text-decoration: none;
	background: transparent;
	border: 0;
	text-transform: capitalize;
	color: currentColor;

	&:visited,
	&:hover,
	&:active {
		color: currentColor;
	}

	&:hover {
		text-decoration: underline;
	}

	&:active {
		outline: none;
	}

	&--is-shown {
		text-decoration: underline;
	}
}

.c-navbar__search-button {
	display: none;

	@include media-query('lg') {
		display: block;
	}
}
