.c-search-institution {
	position: relative;
}

.c-search-institution__header {
	display: none;

	.js & {
		display: block;
	}
}

.c-search-institution__content {
	position: absolute;
	width: 100%;
	z-index: 1;
}

.c-search-institution__message {
	margin-bottom: 0;
}

.c-search-institution__results {
	list-style: none;
	margin: 0;
	padding: 0;
	overflow-y: auto;
	max-height: 300px;
	width: 100%;
}

.c-search-institution__item {
	&:focus {
		.js & {
			background-color: greyscale(90);
			cursor: default;
			outline: none;
		}
	}
}

.c-search-institution__content {
	background-color: #fff;
	border: 1px solid greyscale(80);
	border-top: 0;
	padding: spacing(16);
}
