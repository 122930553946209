.c-lazy-image {
	filter: blur(30px);
	width: 100%;
	height: auto;

	&--loaded {
		filter: none;
		width: auto;
	}
}
