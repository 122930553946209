.c-tabs {
	@include u-list-reset;
	margin-bottom: spacing(16);
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: stretch;

	@include media-query('md') {
		border-bottom: 1px solid greyscale(80);
		justify-content: flex-start;
	}

	&--tight-to-content {
		margin-bottom: spacing(8);
	}
}

.c-tabs__item {
	font-size: $context--font-size-sm;
	line-height: normal;
	flex: 0 0 49%; // Leaves a space, exposed by justify-content in parent
	max-width: 49%;
	margin-top: 2%; // This matches the space set above to make an even grid on small screens

	&:nth-child(-n+2) {
		margin-top: 0; // Don't set margin on first 2
	}

	@include media-query('md') {
		flex: none;
		max-width: none;
		background: #fff;
		margin-top: 0;

		.c-tabs--stretch & {
			flex: 1;
		}
	}
}

.c-tabs__link {
	font-family: $context--font-family-sans;
	display: block;
	padding: .5em 0.8em; // em allows this value to be consistent with a changing font size
	position: relative;
	/* stylelint-disable scss/no-global-function-names */
	background: linear-gradient(greyscale(95), darken(greyscale(95), 9%));
	/* stylelint-enable scss/no-global-function-names */
	border: 1px solid greyscale(80);
	height: 100%; // works with align-items: stretch to ensure tabs have equal height if text wraps on narrow

	@include media-query('md') {
		padding: .8em 2em;
		background: #fff;
		border-color: transparent;
		border-bottom: 0;
		transition: border-top-color .25s ease;

		.c-tabs--stretch & {
			padding-left: 1em;
			padding-right: 1em;
		}
	}

	&::before {
		content: '';
		background-color: color('accent');
		display: block;
		position: absolute;
		height: 2px;
		bottom: 100%;
		right: -1px; // overlap border
		left: -1px; // overlap border
		opacity: 0;
		transform: scaleX(0);
		transition: transform .25s ease, opacity .25s ease;
	}

	.c-tabs__item.is-shown & {
		color: color('corporate');
		background: #fff;

		&:hover {
			text-decoration: none;
		}

		@include media-query('md') {
			position: relative;
			border-color: greyscale(80);

			// Covers the border on the bottom of the active tab
			&::after {
				content: '';
				background-color: #fff;
				display: block;
				position: absolute;
				height: 1px;
				top: 100%;
				right: 0;
				left: 0;
			}
		}

		&::before {
			transform: scaleX(1);
			opacity: 1;
		}
	}
}

.c-tab-content {
	@include hide-toggle;

	.no-js & {
		display: block;
		border-bottom: 1px solid greyscale(80);
		padding-bottom: spacing(16);
		margin-bottom: spacing(16);
	}
}
