// Navs form the basis for many types of navigation
// By default they will display horizontally

.c-nav {
	@include u-list-reset;
	font-size: $nav-font-size;
	font-family: $context--font-family-sans;
	display: flex;
	flex-wrap: wrap;
	line-height: $nav-line-height;
	word-break: normal;
}

.c-nav__item {
	// Nested navs
	> .c-nav {
		padding-left: 1em;
	}
}

.c-nav__link {
	@include u-link-interface;
	@include u-link-inherit;
	display: block;
	padding: $nav-padding;

	&.is-active {
		font-weight: 700;
	}
}

// -------- Modifiers ---------- //

.c-nav--stacked {
	flex-direction: column;

	.c-nav__item {
		width: 100%; // fixes height issue
	}
}

.c-nav--fill {
	.c-nav__item {
		flex-grow: 1;
	}
}

.c-nav--tabs {
	flex-wrap: nowrap;
	text-align: center;
	border-bottom: 1px solid $nav-tabs-border-color;

	.c-nav__item {
		&:not(:first-child) {
			margin-left: -1px;
		}
	}

	.c-nav__link {
		height: 100%;
		background-color: $nav-tabs-item-background-color;
		border: 1px solid $nav-tabs-item-border-color;
		border-bottom: 0;
		padding: $nav-tabs-item-padding;
		position: relative; // Allows z-indexing
		transition: border-left-color .2s ease, border-top-color .2s ease, border-right-color .2s ease, background-color .2s ease;

		&.is-active {
			background-color: $nav-tabs-item-background-color-active;
			z-index: 1;
			border-color: $nav-tabs-item-border-color-active;
			border-bottom-color: transparent;
			position: relative;

			&::after {
				content: '';
				background-color: $nav-tabs-item-background-color-active;
				display: block;
				position: absolute;
				height: 1px;
				top: 100%;
				right: 0;
				left: 0;
			}
		}
	}
}
