.c-image-copyright {
	position: relative;
}

.c-image-copyright__text {
	font-size: $copyright--font-size;
	position: absolute;
	bottom: 0;
	left: 0;
	margin: 0;
	padding: $copyright--padding;
	background-color: $copyright--bg-colour;
	color: $copyright--font-colour;
}
