.publisher-homepage .p-corner-crop {
	display: inline-block;
	max-width: 100%;

	&::before {
		@include triangle($direction: topright, $size-x: 100px, $size-y: 50px, $color: white);
		content: '';
		display: inline-block;
		position: absolute;
		right: 0;
		top: 0;
	}
}

.publisher-homepage h1 {
	font-size: 2.875rem;
}
