@use "sass:color";
@use "sass:math";

$navbar-line-height: $context--line-height-base;
$navbar-padding-mobile: spacing(16) 0 spacing(24);
$navbar-padding-desktop: spacing(16) 0;
$navbar-padding: spacing(16) 0;
$navbar-border: 1px solid greyscale(85);
$navbar-item-spacing: spacing(16);
$navbar-link-padding: spacing(4) spacing(8);
$navbar-background: greyscale(95);
$navbar-link-color: #fff;
$navbar-dropdown-background-color: white;
$navbar-dropdown-border: 1px solid color.scale(color('corporate'), $lightness: 10%);
