.c-crossmark-button {
	padding: 0;
	background: transparent;
	border: 0;

	> img {
		display: block;
		width: 150px;
	}
}
