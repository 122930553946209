.u-button--tertiary {
	font-size: $context--button-font-size-small;
	padding: spacing(8) spacing(16);
}

.u-button--alt-colour-on-mobile {
	@include media-query('sm', 'max') {
		background-color: #f2f2f2;
		background-image: linear-gradient(to bottom, #fff, #f2f2f2);
		border: 1px solid #ccc;
		color: #004b83;

		&:visited {
			color: #004b83;
		}

		&:hover {
			color: #fff;
			background-color: #0061a9;
		}
	}
}
