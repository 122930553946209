.cms-article {
	overflow: hidden; // fixes when content is floated inside

	ul,
	ol {
		li {
			margin-bottom: 0.4em;
		}

		& ul,
		ol {
			margin-top: 0.4em;
		}
	}
}

.cms-content {
	h2 {
		margin-top: 1.5em;
	}

	h3 {
		margin-top: 2em;
	}
}
