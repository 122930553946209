.c-collapse-heading {
	font-family: $context--font-family-sans;
	font-size: inherit;
	font-weight: 400;
	font-style: normal;
	margin: 0;
}

.c-collapse-heading__icon {
	display: inline-block;
	fill: color('action-base');

	[data-toggle="collapse"].is-shown > & {
		transform: rotate(180deg);
	}
}
