$c-badge-font-size: 1.2rem;
$c-badge-font-weight: 700;
$c-badge-padding: 0.4em 0.6em;
$c-badge-line-height: 1.2;
$c-badge-border: false;
$c-badge-border-radius: 3px;

// Can't create dynamic vars in sass, so have to use a map.
$c-badge-themes: (
	'success': color('success'),
	'info': color('action-base'),
	'warning': color('warning'),
	'error': color('error')
);
