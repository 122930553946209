@use "sass:math";

$form-input-border: 1px solid black;
$form-input-border-radius: 3px;
$form-input-box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.21);
$form-input-font-size: 1.4rem;
$form-input-line-height: math.div(18, 14); // Whole numbers work best for vertical-alignment;
$form-input-padding: 0.75em 1em;
$form-select-height: 40px;
$form-field-spacing: 1em;
$form-label-color: #999;
$form-label-spacing: 0.4em;
