.c-steps {
	list-style: none;
	margin: 0;
	padding: 0;
	counter-reset: steps-counter;
}

.c-steps__item {
	margin-bottom: spacing(24);

	&::before {
		@include u-h2;
		font-size: $context--font-size-xxl; // Bigger than h2
		content: counters(steps-counter, '') '.';
		counter-increment: steps-counter;
		display: inline-block;
		margin-right: spacing(4);
		color: color('accent');
	}
}

.c-steps__title {
	@include u-h2;
	display: inline-block;
	margin-bottom: spacing(16);
}

.c-steps__intro {
	font-weight: 700;
}

.c-steps__list {
	/* stylelint-disable scss/no-global-function-names */
	border-top: 1px solid darken(greyscale(80), 20%);
	/* stylelint-enable scss/no-global-function-names */
	border-bottom: 1px solid greyscale(80);
}
