.cms-editor-note {
	border: 1px solid greyscale(80);
	padding: spacing(24) spacing(16);
	color: greyscale(20);

	p,
	h2,
	h3,
	h4,
	h5,
	h6,
	ul,
	li,
	blockquote {
		line-height: 1.4;

		&:last-child {
			margin-bottom: 0;
		}
	}

	p {
		font-size: $context--font-size-sm;
		margin-bottom: spacing(16);

		&:empty {
			margin-bottom: 0;
		}
	}

	h2,
	h3,
	h4,
	h5,
	h6 {
		font-size: $context--font-size-md;
		font-family: $context--font-family-sans;
		color: color('corporate');
		font-weight: 400;
		margin-top: spacing(16);
		margin-bottom: spacing(16);

		&:first-child {
			margin-top: 0;
		}
	}

	ul,
	ol {
		font-size: $context--font-size-sm;
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			margin-bottom: spacing(16);
		}
	}

	img {
		display: none;
	}

	blockquote {
		margin-top: 0;
		margin-bottom: spacing(16);
		padding: 0;
		color: greyscale(20);
		border-bottom: 0;
		font-weight: 400;

		&::before {
			display: none;
		}
	}

	&--small {
		padding-top: spacing(16);
		padding-bottom: spacing(16);

		h2,
		h3,
		h4,
		h5,
		h6,
		p {
			margin-bottom: spacing(8);
		}
	}

	/*
	 * Override Cms wysiwyg styles
	 * because this layout variant
	 * doesn't allow it.
	 */
	.float--right {
		display: block;
		@include media-query('md') {
			float: none;
			margin: 0;
		}
	}

	.float--left {
		@include media-query('md') {
			float: none;
			margin: 0;
		}
	}

	.intro--paragraph {
		padding-bottom: 0;
		border-bottom: 0;
	}

	.divider--paragraph {
		font-size: 0;
		border-top: 0;
	}
}
