.c-panel {
	.c-list-group__item {
		&:first-child {
			padding-top: 0;
		}

		&:last-child {
			padding-bottom: 0;
		}
	}
}
