.app-author-services-banner {
	z-index: 100001;
	color: #222;
	width: 100%;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	font-family: "Daytona W01 Regular",-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen-Sans","Ubuntu","Cantarell","Helvetica Neue",sans-serif;
}

@media only screen and (min-width: 641px) {
	.app-author-services-banner {
		width: 670px;
		left: calc(50% - 335px);
		right: unset;
	}
}

.app-author-services-banner--is-visible {
	animation-name: slide-from-bottom;
	animation-duration: 0.6s;
	transform: translateY(0);
}

.app-author-services-banner__banner-large, .app-author-services-banner__banner-small {
	display: block;
	margin: 0 auto;
	background-color: #fff;
	box-shadow: 0 0 5px 0 rgb(128 128 128 / 10%);
}

.app-author-services-banner__banner-large {
	position: relative;
	display: none;
	max-width: 670px;
	border: 1px solid #b0b0b0;
	border-bottom: none;
}

.app-author-services-banner__banner-small {
	border-top: 1px solid #b0b0b0;
}

@media only screen and (min-width: 641px) {
	.app-author-services-banner__banner-large {
		display: block;
	}
	.app-author-services-banner__banner-small {
		display: none;
	}
}

.app-author-services-banner__large-container, .app-author-services-banner__small-container, .app-author-services-banner__large-content-container {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
}

.app-author-services-banner__large-container, .app-author-services-banner__small-container {
	width: 100%;
}

.app-author-services-banner__small-container {
	justify-content: space-between;
}

.app-author-services-banner__large-content-container {
	padding: 16px 16px 16px 0;
	flex-grow: 1;
}

.app-author-services-banner__small-content-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	flex-wrap: nowrap;
	padding: 12px;
}

.app-author-services-banner__large-text-container {
	display: flex;
	justify-content: space-between;
	gap: 16px;
	margin-left: 24px;
	margin-right: 16px;
	flex-direction: column;
	flex-wrap: nowrap;
}

.app-author-services-banner__small-text-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: nowrap;
	gap: 16px;
	margin-top: 12px;
}

.app-author-services-banner__text {
	font-size: 12px;
	font-weight: 400;
	line-height: 1.273;
	max-width: 150px;
}

@media only screen and (min-width: 641px) {
	.app-author-services-banner__text {
		font-size: 22px;
		max-width: 270px;
	}
}

.app-author-services-banner__small-image {
	display: block;
	max-width: 90px;
}

.app-author-services-banner__large-image {
	display: block;
}

.app-author-services-banner__image-cover-text {
	position: absolute;
	top: 137px;
	background-color: rgba(255,255,255,0.8);
	width: 100%;
	font-size: 12px;
	font-weight: 400;
	line-height: 1.273;
	padding: 4px 0;
	letter-spacing: -0.12px;
	text-align: center;
}

.app-author-services-banner__button {
	padding: 6px 8px;
	background-color: #EF2929;
	color: #fff;
	display: inline-block;
	max-width: 90px;
	text-align: center;
	line-height: 1.273;
	font-size: 11px;
}

a.app-author-services-banner__button:visited {
	color: #fff;
}

.app-author-services-banner__button span {
	font-weight: 400;
	letter-spacing: -0.13px;
	display: inline-block;
	transform: translateY(1px);
}

@media only screen and (min-width: 641px) {
	.app-author-services-banner__button {
		max-width: 124px;
		font-size: 16px;
	}
}

.app-author-services-banner__logo {
	max-width: 83px;
}

@media only screen and (min-width: 641px) {
	.app-author-services-banner__logo {
		max-width: 162px;
	}
}

.app-author-services-banner__close-container {
	width: 25px;
	height: 25px;
	position: absolute;
	right: 9px;
	top: 11px;
	border: 0;
	display: inline-block;
}

@media only screen and (min-width: 641px) {
	.app-author-services-banner__close-container {
		right: 8px;
		top: 10px;
	}
}

.app-author-services-banner__close {
	margin-top: 0;
	background: 0 0;
	color: #222;
	vertical-align: top;
	border: 0;
	padding-right: 0;
}

@media only screen and (min-width: 641px) {
	.app-author-services-banner__close {
		height: inherit;
	}
}

@keyframes slide-from-bottom {
	0% {
		max-height: 0;
	}
	100% {
		max-height: 200px;
	}
}
