.c-badge {
	@include unvisited-link;
	font-size: $c-badge-font-size;
	font-weight: $c-badge-font-weight;
	padding: $c-badge-padding;
	line-height: $c-badge-line-height;
	transition: background-color .2s ease, border-color .2s ease;

	@if $c-badge-border == false {
		border: none;
	}

	@if $c-badge-border-radius {
		border-radius: $c-badge-border-radius;
	}

	@each $key, $value in $c-badge-themes {
		&--#{$key} {
			background-color: $value;
			color: shade($value, 70%);

			&:hover {
				background-color: shade($value, 5%);
			}

			@if $c-badge-border == true {
				border: 1px solid shade($value, 10%);
			}
		}
	}
}
