.c-media {
	blockquote {
		border: 0;
		padding: 0;
		font-size: inherit;
		margin-bottom: 0;
	}
}

.c-media__title {
	font-family: $context--font-family-sans;
}
