.c-associated-societies__title {
	font-size: $context--font-size-sm;
	font-family: $context--font-family-sans;
	margin-bottom: spacing(16);
}

.c-associated-societies__list {
	@include u-list-reset;
}

.c-associated-societies__list-item {
	& + & {
		margin-top: spacing(8);
	}

	p {
		font-size: $context--font-size-sm;
		font-family: $context--font-family-sans;
		margin: 0;
	}
}
