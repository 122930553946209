@use "sass:color";

.c-jump-navigation {
	overflow-x: scroll;
	white-space: nowrap;

	@include media-query('md') {
		overflow-x: visible;
		white-space: normal;
	}
}

.c-jump-navigation__title {
	@include u-h3;
}

.c-jump-navigation__item {
	display: inline;
	margin: 0;
	font-size: 0;

	@include media-query('md') {
		font-size: $context--font-size-md;
	}
}

.c-jump-navigation__link {
	font-size: $context--font-size-md;
	background-color: color.scale(color('accent'), $lightness: 90%);
	border-top: 1px solid color('accent');
	border-bottom: 1px solid color('accent');
	display: inline-block;
	padding: spacing(4);
	text-align: center;
	text-decoration: none;
	min-width: 2.5em;
	color: color('corporate');

	@include media-query('md') {
		border-left: 1px solid color('accent');
		border-right: 1px solid color('accent');
	}

	&:hover {
		background-color: color.scale(color('accent'), $lightness: 73%);
		color: color('corporate');
	}

	&:visited {
		color: color('corporate');
	}

	&--disabled {
		color: greyscale(40);

		&:hover {
			background-color: color.scale(color('accent'), $lightness: 90%);
			color: greyscale(40);
			cursor: pointer;
		}
	}

	.c-jump-navigation .c-jump-navigation__item:first-of-type & {
		border-left: 1px solid color('accent');
	}

	.c-jump-navigation .c-jump-navigation__item:last-of-type & {
		border-right: 1px solid color('accent');
	}
}
