.app-login {
	@include media-query('lg') {
		display: flex;
		align-items: flex-start;
	}
}

.app-login__form {
	margin-bottom: spacing(24);

	@include media-query('lg') {
		flex: 1 1 auto;
		margin-right: spacing(32);
	}
}

.app-login__details {
	font-size: $context--font-size-sm;
	padding: spacing(16);
	border: 1px solid color(action-light);
	background-color: color(action-background-light);

	> *:last-child {
		margin-bottom: 0;
	}

	@include media-query('lg') {
		flex: 0 1 auto;
		max-width: 35%;
	}
}
