.c-logo {
	display: flex;
	align-items: baseline;

	// If logo is linked, make this the flex container
	> a {
		display: flex;
		align-items: baseline;

		&:visited,
		&:hover,
		&:focus,
		&:active {
			text-decoration: none;
		}
	}

	img {
		display: block;
	}
}

.c-logo__strapline {
	display: none;

	@include media-query('sm') {
		display: block;
		margin: 0 0 0 spacing(16);
		transform: translateY(1px);
	}
}
