@use "sass:color";

.c-journal-header {
	overflow: hidden;

	&--genome-medicine,
	&--genome-biology {
		border-top: 1px solid color.scale(color('corporate'), $lightness: 20%);
		background-color: color('corporate');

		@include media-query('sm') {
			background-repeat: no-repeat;
			background-position: center top;
		}

		.c-journal-header__submit-button {
			@include button($context--button-default);
			width: auto;
		}
	}

	&--genome-medicine {
		@include media-query('sm') {
			background-image: url('/static/images/bmc/identities/flagships/genome-medicine.jpg');
		}
	}

	&--genome-biology {
		@include media-query('sm') {
			background-image: url('/static/images/bmc/identities/flagships/genome-biology.jpg');
		}
	}

	&--bmc-medicine,
	&--bmc-biology,
	&--bmc-global-and-public-health {
		border-top: 1px solid color.scale(color('corporate'), $lightness: 30%);
		background-color: #ecebe7;

		.c-journal-header__submit-button {
			@include button($context--button-primary);
			width: auto;

		}
	}

	&--bmc-medicine {
		@include media-query('sm') {
			background: url('/static/images/bmc/identities/flagships/bmc-medicine.jpg') no-repeat center top #ecebe7;
		}
	}

	&--bmc-biology {
		@include media-query('sm') {
			background: url('/static/images/bmc/identities/flagships/bmc-biology.jpg') no-repeat center top #ecebe7;
		}
	}

	&--bmc-global-and-public-health {
		@include media-query('sm') {
			background: url('/static/images/bmc/identities/flagships/bmc-global-and-public-health-logo.jpg') no-repeat center top #ecebe7;
		}
	}
}

.c-journal-header__inner {
	padding-top: spacing(8);
	padding-bottom: spacing(8);

	@include media-query('sm') {
		display: flex;
		justify-content: space-between;
	}

	&--featured-journal {
		@include media-query('sm') {
			height: 90px;

			.journal-homepage & {
				height: 123px; // Magic number to ensure flagship image shows
			}
		}
	}
}

.c-journal-header__identity {
	background-size: auto 80px;
	min-height: 16px;
	padding: 6px 0;

	@include media-query('sm') {
		background-position: left center;
	}

	&--default {
		background: url('/static/images/bmc/identities/patterns/default.svg') no-repeat left top color('series-default', $journal-colors);

		.c-journal-header__submit-button {
			@include button($context--button-primary);
			width: auto;
		}
	}

	&--featured-journal {
		background: none;
		background-size: auto;
		min-height: auto;

		.c-journal-header--genome-medicine &,
		.c-journal-header--genome-biology & {
			background-color: rgba(0, 44, 81, 0.75); // a layer based on the background colour of the journal image
		}

		.c-journal-header--bmc-medicine &,
		.c-journal-header--bmc-biology &,
		.c-journal-header--bmc-global-and-public-health & {
			background-color: rgba(236, 235, 231, 0.85);
		}
	}

	&--gold-dark-blue,
	&--gold-grey {
		background-color: color('aj-yellow', $journal-colors);
	}

	&--grape-dark-blue,
	&--grape-light-grey {
		background-color: color('aj-purple', $journal-colors);
	}

	&--jungle-light-blue,
	&--jungle-light-green,
	&--jungle-yellow {
		background-color: color('aj-dark-green', $journal-colors);
	}

	&--lime-dark-blue,
	&--lime-dark-green {
		background-color: color('aj-green', $journal-colors);
	}

	&--midnight-light-grey,
	&--midnight-purple,
	&--midnight-yellow {
		background-color: color('corporate');
	}

	&--ocean-light-blue {
		background-color: color('aj-dark-blue', $journal-colors);
	}

	&--ruby-dark-blue,
	&--ruby-dark-red {
		background-color: color('aj-red', $journal-colors);
	}

	&--sky-blue,
	&--sky-dark-blue {
		background-color: color('aj-blue', $journal-colors);
	}

	&--slate-purple,
	&--slate-yellow {
		background-color: greyscale(65);
	}

	&--wine-purple,
	&--wine-red {
		background-color: color('aj-dark-red', $journal-colors);
	}

	&--gold-dark-blue,
	&--grape-dark-blue,
	&--lime-dark-blue,
	&--ruby-dark-blue,
	&--sky-dark-blue {
		background-image: url('/static/images/bmc/identities/patterns/darkblue.svg');
	}

	&--gold-grey {
		background-image: url('/static/images/bmc/identities/patterns/grey.svg');
	}

	&--jungle-light-blue,
	&--ocean-light-blue {
		background-image: url('/static/images/bmc/identities/patterns/lightblue.svg');
	}

	&--jungle-light-green {
		background-image: url('/static/images/bmc/identities/patterns/lightgreen.svg');
	}

	&--grape-light-grey,
	&--midnight-light-grey {
		background-image: url('/static/images/bmc/identities/patterns/lightgrey.svg');
	}

	&--jungle-yellow,
	&--midnight-yellow,
	&--slate-yellow {
		background-image: url('/static/images/bmc/identities/patterns/yellow.svg');
	}

	&--lime-dark-green {
		background-image: url('/static/images/bmc/identities/patterns/darkgreen.svg');
	}

	&--midnight-purple,
	&--slate-purple,
	&--wine-purple {
		background-image: url('/static/images/bmc/identities/patterns/purple.svg');
	}

	&--ruby-dark-red {
		background-image: url('/static/images/bmc/identities/patterns/darkred.svg');
	}

	&--sky-blue {
		background-image: url('/static/images/bmc/identities/patterns/blue.svg');
	}

	&--wine-red {
		background-image: url('/static/images/bmc/identities/patterns/red.svg');
	}

	&--gold-grey,
	&--gold-dark-blue,
	&--grape-dark-blue,
	&--grape-light-grey,
	&--jungle-light-blue,
	&--jungle-light-green,
	&--jungle-yellow,
	&--midnight-light-grey,
	&--midnight-purple,
	&--midnight-yellow,
	&--ocean-light-blue,
	&--ruby-dark-blue,
	&--ruby-dark-red,
	&--sky-blue,
	&--sky-dark-blue,
	&--wine-purple,
	&--wine-red {
		.c-journal-header__submit-button {
			@include button($context--button-default);
			width: auto;
		}
	}

	&--lime-dark-blue,
	&--lime-dark-green,
	&--slate-purple,
	&--slate-yellow {
		.c-journal-header__submit-button {
			@include button($context--button-primary);
			width: auto;
		}
	}
}

.c-journal-header__search {
	width: 100%;
	margin-bottom: spacing(8);

	@include media-query('sm') {
		width: auto;
		margin-bottom: 0;
		margin-right: spacing(16);
	}

	.c-form-field {
		@include media-query('sm') {
			max-width: 500px;
		}
	}
}

.c-journal-header__submit {
	width: 100%;

	@include media-query('sm') {
		width: auto;
	}
}
