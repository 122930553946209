.c-journal-title {
	@include u-h2;
	display: flex;
	font-style: normal;
	margin-bottom: 0;
	margin-top: 0;

	> a {
		color: color('corporate');
		display: flex;
		text-decoration: none;

		&:visited,
		&:hover,
		&:active {
			color: color('corporate');
		}

		.c-journal-header__inner--featured-journal & {
			align-self: center;
		}
	}

	&--footer {
		font-size: $context--font-size-lg;
		margin-bottom: spacing(8);

		.c-journal-title__logo {
			margin-right: 0;
			transform: scale(0.75);
			transform-origin: left;
		}
	}
}

.c-journal-title__logo {
	display: block; // IE fix for inline elements that are flex items
	margin-right: spacing(16);
	max-height: 40px;
}

.c-journal-title__text {
	display: block; // IE fix for inline elements that are flex items
	flex: 0 1 auto; // IE fix as 0 0 auto is default there
	align-self: center;
}

.c-journal-title__featured-journal-lines {
	line-height: 0;

	@include media-query('sm') {
		position: relative;

		&::before,
		&::after {
			content: '';
			display: block;
			position: absolute;
		}

		&--genome-medicine {
			&::before,
			&::after {
				background-color: color('flagship-medicine', $journal-colors);
			}

			&::before {
				right: 16px; // TODO: can these values be calculated?
				bottom: 54px;
				height: 100vh;
				width: 2px;
			}

			&::after {
				left: 272px;
				bottom: 37px;
				width: 100vw;
				height: 2px;
			}

			.journal-homepage &::before {
				right: 19px;
				bottom: 63px;
				width: 3px;
			}

			.journal-homepage &::after {
				left: 318px;
				bottom: 43px;
				height: 3px;
			}
		}

		&--genome-biology {
			&::before,
			&::after {
				background-color: color('flagship-biology', $journal-colors);
			}

			&::before {
				right: 256px;
				bottom: 16px;
				width: 100vw;
				height: 2px;
			}

			&::after {
				left: 256px;
				bottom: 37px;
				width: 100vw;
				height: 2px;
			}

			.journal-homepage &::before {
				right: 318px;
				bottom: 20px;
				height: 3px;
			}

			.journal-homepage &::after {
				left: 316px;
				bottom: 46px;
				height: 3px;
			}
		}

		&--bmc-medicine {
			&::before,
			&::after {
				background-color: #00cea9;
			}

			&::before {
				right: 15px; // TODO: can these values be calculated?
				bottom: 53px;
				height: 100vh;
				width: 2px;
			}

			&::after {
				left: 222px;
				bottom: 38px;
				width: 100vw;
				height: 2px;
			}

			.journal-homepage &::before {
				right: 22px;
				bottom: 78px;
				width: 3px;
			}

			.journal-homepage &::after {
				left: 317px;
				bottom: 54px;
				height: 3px;
			}
		}

		&--bmc-biology {
			&::before,
			&::after {
				background-color: #de5426;
			}

			&::before {
				right: 207px;
				top: 0;
				width: 100vw;
				height: 2px;
			}

			&::after {
				left: 207px;
				bottom: 0;
				width: 100vw;
				height: 2px;
			}

			.journal-homepage &::before {
				right: 207px;
				top: 0;
				height: 3px;
			}

			.journal-homepage &::after {
				left: 207px;
				bottom: 0;
				height: 3px;
			}
		}

		&--bmc-global-and-public-health {
			&::before,
			&::after {
				background-color: color('flagship-global-public-health', $journal-colors);
			}

			&::before {
				right: 10px;
				bottom: 54px;
				height: 100vh;
				width: 2px;
			}

			&::after {
				left: 169px;
				bottom: 42px;
				width: 100vw;
				height: 2px;
			}

			.journal-homepage &::before {
				right: 19px;
				bottom: 85px;
				width: 3px;
			}

			.journal-homepage &::after {
				left: 318px;
				top: 20px;
				height: 3px;
			}
		}
	}
}

.c-journal-title__featured-journal-logo {
	@include media-query('sm', 'max') {
		max-width: 200px;
	}

	.journal-homepage & {
		width: 318px;
	}
}
