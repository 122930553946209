.app-subject-grid {
	display: flex;
	justify-content: space-between;
	align-content: center;
	flex-wrap: wrap;
}

.app-subject-grid > * {
	flex: 1 0 100%;

	@include media-query('md') {
		flex: 0 0 48%;
	}
}
