.intro--paragraph {
	padding-bottom: spacing(16);
	border-bottom: 1px solid greyscale(80);
}

.divider--paragraph {
	border-top: 1px solid greyscale(80);
	padding-top: 1.5em;
}

.float--right {
	float: right;
	margin-left: spacing(16);
	margin-bottom: spacing(16);

	@include media-query('lg') {
		margin-left: spacing(16);
		margin-bottom: spacing(16);
	}
}

.float--left {
	float: left;
	margin-right: spacing(16);
	margin-bottom: spacing(16);

	@include media-query('lg') {
		margin-right: spacing(16);
		margin-bottom: spacing(16);
	}
}

.strike {
	text-decoration: line-through;
}

.underline {
	text-decoration: underline;
}
