// Even though we control the HTML for this, it will live in fulltext until we decide
// how to approach instances where the JS hooks onto classnames and is used across multiple publishers.

.tooltip-tether {
	position: absolute;
	margin-top: 19px;
	margin-left: -1px;
	padding: spacing(16);
	padding-right: 14px;
	border: 1px solid greyscale(80);
	box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
	z-index: get-z-index(front-content);
	background-color: #fff;
	width: 50%;
	max-width: 300px;

	@include media-query('md') {
		width: auto;
	}

	&--opr {
		width: 250px;
	}

	.js-CollapseSection & {
		display: none;

		@include media-query('md') {
			display: block;
		}
	}

	.js-CollapseSection.is-shown & {
		display: block;
	}

	&.tether-element-attached-bottom {
		margin-top: -19px;
	}

	&__arrow {
		background-image: url('/static/images/bmc/fulltext/tooltip-arrow.svg');
		background-size: contain;
		width: 36px;
		height: 18px;
		z-index: get-z-index(front-content);
		position: absolute;
		bottom: 100%;

		.tether-element-attached-right & {
			right: 20px;
		}

		.tether-element-attached-bottom &,
		.tether-element-pinned-left & {
			top: 100%;
			transform: rotate(180deg);
		}
	}

	&__heading {
		margin-bottom: 12px;
		line-height: normal;
	}

	&__sub-heading {
		font-size: $context--font-size-sm;
	}

	&__body {
		font-size: $context--font-size-sm;
		color: greyscale(40);

		> p {
			margin-bottom: spacing(8);

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__indexed-item {
		list-style-type: decimal;
		display: list-item;
		margin: 0.5em 1em;
		padding: 0 0.2em;
		white-space: normal;
	}

	// TODO: Remove reliance on `& a` by refactoring tooltip JS

	a,
	&__link {
		display: block;
		margin-top: spacing(16);
	}

	&__link-icon {
		display: inline-block;
		margin: 0 spacing(8) 0 0;
		top: 1px;
		position: relative;
	}

	&__close-button {
		font-size: $context--font-size-sm;
		background-color: transparent;
		border: 0;
		padding: spacing(8);
		position: absolute;
		top: -8px;
		right: -3px;
		display: block;

		&:after {
			content: '';
			width: 9px;
			height: 9px;
			background: transparent url("/static/images/bmc/fulltext/close.svg");
			background-size: cover;
			display: block;
		}
	}

	.ContactIcon,
	.OrcidIcon {
		margin-right: spacing(4);
	}
}
