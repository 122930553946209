@use "sass:color";

.c-separator {
	@include u-keyline;

	&--top {
		@include u-keyline($direction: top);
	}

	&--heavy {
		border-color: color.scale(color('corporate'), $lightness: 10%);
		border-bottom-width: 2px;

		&.c-separator--top {
			border-top-width: 2px;
		}
	}
}

.c-separator-inline {
	@include u-keyline($direction: right);
	display: inline;
}
