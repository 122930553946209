.cms-collection__title {
	border-top: 1px solid greyscale(80);
	margin-top: spacing(24);
	padding-top: spacing(24);
}

.cms-collection:first-child .cms-collection__title {
	border-top: 0;
	margin-top: 0;
	padding-top: 0;
}

.cms-collection__row {
	@include media-query('lg') {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
}

.cms-collection__column {
	@include media-query('lg') {
		// To use calc in flexbox in IE11 you must use the long hand. flex: 1 1 calc(50% - 20px) does not work.
		// The above does not work for IE10. https://github.com/philipwalton/flexbugs#flexbug-8
		flex-grow: 0;
		flex-shrink: 1;
		flex-basis: calc(50% - 20px);
		margin-top: 0;
		// IE10 fallback to prevent content overlapping sidebar
		width: 100%;
	}
}

.cms-collection--untitled .cms-collection__column-inner {
	border-top: 1px solid greyscale(80);
	margin-top: spacing(24);
	padding-top: spacing(24);
}

// if not split into columns (i.e. <lg), ensure that the first column of the first collecton doesn't get the border
.cms-collection--untitled:first-child .cms-collection__column-inner {
	@include media-query('lg') {
		border-top: 0;
		margin-top: 0;
		padding-top: 0;
	}
}

.cms-collection--untitled:first-child .cms-collection__column:first-child .cms-collection__column-inner:first-child {
	@include media-query('lg', 'max') {
		border-top: 0;
		margin-top: 0;
		padding-top: 0;
	}
}

h1 + .cms-collection--untitled .cms-collection__column-inner {
	border-top: 0;
	margin-top: 0;
	padding-top: 0;
}
