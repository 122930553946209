// TODO: Could this module just use 'collapse'? Question to be answered around aria attributes, e.g. aria-selected (on the tab itself)

.c-tab-content {
	.js & {
		display: none;

		&.is-active {
			display: block;
		}
	}
}
