@mixin u-webfont($type: 'serif') {
	$family-fallback: $context--font-family-serif;
	$family: $context--font-family-serif-webfont;

	@if $type == 'sans' {
		$family-fallback: $context--font-family-sans;
		$family: $context--font-family-sans-webfont;
	}

	@else if $type != 'sans' AND $type != 'serif' {
		@warn 'Permitted values to u-webfont mixin are "sans" or "serif"';
	}

	font-family: $family-fallback;

	html.webfonts-loaded & {
		font-family: $family;
	}
}
