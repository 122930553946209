.c-journal-footer {
	font-size: $context--font-size-md;
	border-top: 1px solid greyscale(80);
	padding-top: spacing(16);
	padding-bottom: spacing(16);
}

.c-journal-footer__inner {
	@include u-container;

	@include media-query('md') {
		display: flex;
		justify-content: space-between;
	}
}

.c-journal-footer__summary {
	margin-bottom: spacing(16);

	@include media-query('md') {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-bottom: 0;
	}
}

.c-journal-footer__title {
	font-size: $context--font-size-lg;
	margin-bottom: spacing(8);
	display: flex;
	font-style: normal;
}

.c-journal-footer__logo {
	display: block; // IE fix for inline elements that are flex items
	transform: scale(0.75);
	transform-origin: left;
}

.c-journal-footer__title-text {
	display: block; // IE fix for inline elements that are flex items
	flex: 0 1 auto; // IE fix as 0 0 auto is default there
	align-self: center;
}

.c-journal-footer__issn {
	color: color('corporate');
	margin-bottom: 0;
}

.c-journal-footer__contact-title {
	font-size: $context--font-size-md;
	font-style: normal;

	@include media-query('md') {
		margin-bottom: spacing(16);
	}

	&--adjust-for-logo {
		margin-top: 18px; // magic number that aligns contact title with journal title when a journal logo exists
	}
}

.c-journal-footer__contact-list {
	list-style: none;
	margin: 0;
	padding: 0;
	color: color('corporate');
}
