.c-enhanced-select {
	position: relative;
}

.c-enhanced-select__filter {
	background-color: #fff;
	border: 1px solid greyscale(80);
	display: none;
	left: 0;
	margin-top: -1px;
	padding: spacing(16);
	position: absolute;
	width: 100%;
	z-index: 11;

	&.is-shown {
		display: block;
	}
}

.c-enhanced-select__chosen {
	font-size: $context--font-size-sm;
	background-color: #fff;
	border: 1px solid greyscale(80);
	cursor: pointer;
	display: flex;
	padding: 0.75em 1em;
	align-items: center;
	justify-content: space-between;

	svg {
		fill: greyscale(40);
		transition: transform 250ms ease;
	}

	&.is-shown {
		svg {
			transform: rotate(180deg);
		}
	}

	&.is-hidden {
		display: none;
	}
}

.c-enhanced-select__list {
	margin: 0;
	padding: 0;
	list-style: none;
	max-height: 300px;
	overflow-y: auto;
	width: 100%;
}

.c-enhanced-select__item {
	padding: spacing(4) spacing(8);

	&.is-highlighted {
		outline: none;
		background-color: greyscale(95);
	}
}

.c-enhanced-select_search-icon {
	@include u-icon(14px, 14px);
	position: absolute;
	right: spacing(24);
	top: 30px; // magic number
}
