@font-face {
	font-family: 'Europa';
	font-display: swap;
	font-style: normal;
	font-weight: 400;
	src: local('Europa-Regular'), url('/static/app-bmc/fonts/europa-regular.woff2') format('woff2'), url('/static/app-bmc/fonts/europa-regular.woff') format('woff');
}

@font-face {
	font-family: 'Europa';
	font-display: swap;
	font-style: normal;
	font-weight: 700;
	src: local('Europa-Bold'), url('/static/app-bmc/fonts/europa-bold.woff2') format('woff2'), url('/static/app-bmc/fonts/europa-bold.woff') format('woff');
}

/* latin-ext */
@font-face {
	font-family: 'Noto Serif';
	font-display: swap;
	font-style: normal;
	font-weight: 400;
	src: local('Noto Serif'), local('NotoSerif'), url("https://fonts.gstatic.com/s/notoserif/v6/ga6Iaw1J5X9T9RW6j9bNfFkWaDq8fMVxMw.woff2") format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
	font-family: 'Noto Serif';
	font-display: swap;
	font-style: normal;
	font-weight: 400;
	src: local('Noto Serif'), local('NotoSerif'), url("https://fonts.gstatic.com/s/notoserif/v6/ga6Iaw1J5X9T9RW6j9bNfFcWaDq8fMU.woff2") format('woff2'), url("https://fonts.gstatic.com/s/notoserif/v6/ga6Iaw1J5X9T9RW6j9bNfFcWbg.woff") format('woff');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
	font-family: 'Noto Serif';
	font-display: swap;
	font-style: italic;
	font-weight: 400;
	src: local('Noto Serif Italic'), local('NotoSerif-Italic'), url("https://fonts.gstatic.com/s/notoserif/v6/ga6Kaw1J5X9T9RW6j9bNfFImZDC7XsdBMr8R.woff2") format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
	font-family: 'Noto Serif';
	font-display: swap;
	font-style: italic;
	font-weight: 400;
	src: local('Noto Serif Italic'), local('NotoSerif-Italic'), url("https://fonts.gstatic.com/s/notoserif/v6/ga6Kaw1J5X9T9RW6j9bNfFImajC7XsdBMg.woff2") format('woff2'), url("https://fonts.gstatic.com/s/notoserif/v6/ga6Kaw1J5X9T9RW6j9bNfFImajC9.woff") format('woff');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
	font-family: 'Noto Serif';
	font-display: swap;
	font-style: normal;
	font-weight: 700;
	src: local('Noto Serif Bold'), local('NotoSerif-Bold'), url("https://fonts.gstatic.com/s/notoserif/v6/ga6Law1J5X9T9RW6j9bNdOwzfRmece9LOocoDg.woff2") format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
	font-family: 'Noto Serif';
	font-display: swap;
	font-style: normal;
	font-weight: 700;
	src: local('Noto Serif Bold'), local('NotoSerif-Bold'), url("https://fonts.gstatic.com/s/notoserif/v6/ga6Law1J5X9T9RW6j9bNdOwzfReece9LOoc.woff2") format('woff2'), url("https://fonts.gstatic.com/s/notoserif/v6/ga6Law1J5X9T9RW6j9bNdOwzfReedw.woff") format('woff');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
