.cms-video {
	iframe {
		max-width: 100%;
	}
}

.cms-video__body {
	margin-top: spacing(16);
	line-height: 1.4;

	> p {
		margin-bottom: 0.5em;

		&:last-child {
			margin-bottom: 0;
		}
	}
}
