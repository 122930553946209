.c-popup-search {
	position: relative;
	z-index: 10;
	background-color: $popup-search-background-color;
	padding: $popup-search-padding;
	box-shadow: $popup-search-box-shadow;

	@include media-query('lg') {
		.js & {
			position: absolute;
			width: 100%;
			top: 100%;
		}
	}
}

.c-popup-search__container {
	@include media-query('lg') {
		margin: auto;
		max-width: 70%;
	}
}
