/* grid-column-gap and grid-template-areas are duplicated in places. Do not remove the duplicates, this must be done for autoprefixer to correctly apply IE vendor prefixes */

.c-page-layout {
	display: grid;

	&--sticky-footer {
		grid-template-rows: auto 1fr auto;
		min-height: 100vh;
		grid-template-columns: 1fr;
		grid-template-areas:
			"header"
			"body"
			"footer";
	}

	&--landing {
		grid-column-gap: 30px;
		grid-template-columns: 1fr;
		grid-template-areas:
			"main"
			"side";

		@include media-query('md') {
			grid-column-gap: 30px;
			grid-template-columns: 1fr 160px;
			grid-template-areas:
				"main    side";
		}

		@include media-query('lg') {
			grid-column-gap: 50px;
			grid-template-columns: 1fr 190px;
			grid-template-areas:
				"main    side";
		}
	}

	&--default {
		grid-column-gap: 30px;
		grid-template-columns: 1fr;
		grid-template-areas:
			"nav"
			"main"
			"side";

		@include media-query('md') {
			grid-column-gap: 30px;
			grid-template-columns: 160px 1fr;
			grid-template-areas:
				"nav     main"
				" .      side";
		}

		@include media-query('lg') {
			grid-column-gap: 50px;
			grid-template-columns: 190px 1fr;
			grid-template-areas:
				"nav     main"
				" .      side";
		}

		@include media-query('xl') {
			grid-column-gap: 50px;
			grid-template-columns: 190px 1fr 190px;
			grid-template-areas:
				"nav     main    side";
		}
	}

	&--fulltext {
		grid-column-gap: 30px;
		grid-template-columns: 1fr;
		grid-template-areas:
			"nav"
			"main"
			"side";

		@include media-query('md') {
			grid-column-gap: 30px;
			grid-template-columns: 1fr 160px;
			grid-template-areas:
				"main    side";
		}

		@include media-query('lg') {
			grid-column-gap: 50px;
			grid-template-columns: 1fr 190px;
			grid-template-areas:
				"main    side";
		}

		@include media-query('xl') {
			grid-column-gap: 50px;
			grid-template-columns: 190px 1fr 190px;
			grid-template-areas:
				"nav     main    side";
		}
	}
}

.c-page-layout__nav {
	grid-area: nav;
}

.c-page-layout__main {
	@include word-wrap;
	grid-area: main;
	min-width: 0; // Stops area growing to fit minimum content width
}

.c-page-layout__side {
	grid-area: side;

	@include media-query('lg', 'max') {
		margin-top: spacing(24);
	}
}

.c-page-layout__header {
	grid-area: header;
}

.c-page-layout__body {
	grid-area: body;
}

.c-page-layout__footer {
	grid-area: footer;
}
