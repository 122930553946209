// move into context
video {
	display: block;
}

.c-video {
	border: 1px solid greyscale(80);
	background-color: greyscale(95);
}

.c-video__wrapper {
	position: relative;

	&::before {
		content: "";
		width: 1px;
		margin-left: -1px;
		float: left;
		height: 0;
		padding-top: 56.28272%; // 16:9
	}

	&::after {
		content: "";
		display: table;
		clear: both;
	}

	.c-video--miVMPro & {
		&::before {
			padding-top: 60.4%; // allows extra height for VM Pro control panel
		}
	}
}
.c-video__content {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	iframe {
		border: none;
		width: 100%;
		height: 100%;
	}
}

.c-video__warning {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
	right: 0;
	padding: spacing(48);
	margin: 0;
	text-align: center;
}

.c-video__warning--hide {
	display: none;
}

.c-video__caption {
	padding: spacing(16);
	font-size: .875rem;
	background-color: white;
	border-top: 1px solid greyscale(80);

	*:last-child {
		margin-bottom: 0;
	}
}
