@use "sass:color";

.ctx-sidebar-nav {
	.c-nav__item {
		&.is-active {
			background-color: color.scale(color('accent'), $lightness: 85%);
		}

		// Horrible nested padding to allow hover background of link
		/* stylelint-disable max-nesting-depth */
		> .c-nav {
			padding-left: 0;

			> .c-nav__item {
				> .c-nav__link {
					padding-left: 3em;
				}

				> .c-nav {
					> .c-nav__item {
						> .c-nav__link {
							padding-left: 4em;
						}
					}
				}
			}
		}
		/* stylelint-enable max-nesting-depth */
	}

	.c-nav__link {
		padding-left: 2em;
		padding-right: 2em;
		transition: background-color .2s ease;

		&:hover {
			background-color: color.scale(color('accent'), $lightness: 70%);
		}
	}

	.ctx-sidebar-nav__openIcon {
		margin-left: -2em;
	}
}
