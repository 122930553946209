.cms-item {
	font-family: $context--font-family-sans;
	position: relative;
}

.cms-item + .cms-item {
	margin-top: spacing(24);
	padding-top: spacing(24);
	border-top: 1px solid greyscale(80);
}

.cms-item + .cms-item.cms-item--indexed {
	border-top: 0;
}

.cms-item + .cms-item.cms-collection {
	padding-top: 0;
	border-top: 0;

	> .cms-collection__title {
		padding-top: spacing(24);
		border-top: 1px solid greyscale(80);
	}
}

.cms-item + .cms-item.cms-collection--split {
	margin-top: 0;
	padding-top: 0;
	border-top: 0;
}

.cms-item + .cms-item.cms-editor-note {
	padding-top: spacing(24);
}

.main-sidebar .cms-item + .cms-item {
	margin-top: 0;
	padding-top: 0;
	border-top: 0;
}

.cms-item p {
	margin-bottom: 1.5em;
}
