// Should be a generic dropdown button component?

.c-export-citations {
	@include hide-toggle;
	border: 1px solid greyscale(80);
}

.c-export-citations__section {
	padding-bottom: spacing(8);

	& + & {
		border-top: 1px solid greyscale(80);
	}
}

.c-export-citations__title {
	font-size: $context--font-size-sm;
	color: inherit;
	font-style: normal;
	font-weight: normal;
	margin-bottom: spacing(4);
	padding: spacing(8) spacing(8) 0;
}

.c-export-citations__list {
	list-style: none;
	margin: 0;
	padding: 0;

	a {
		font-size: $context--font-size-sm;
		display: block;
		padding: spacing(4) spacing(8);
		text-decoration: none;

		&:hover {
			background-color: color('action-base');
			color: #fff;

			.c-export-citations__icon {
				fill: #fff;
			}
		}
	}
}

.c-export-citations__icon {
	fill: color('action-base');
}
