@mixin heading-link {
	a {
		text-decoration: none;

		&:hover,
		&:active {
			color: color('action-base');
		}

		&:visited {
			color: color('visited');
		}

		&:hover {
			text-decoration: underline;
		}

		&:active {
			color: color('action-light');
			text-decoration: none;
		}
	}
}

@mixin u-h1 {
	@include heading-link;
	@include u-webfont('sans');
	font-size: $context--font-size-xxl;
	font-style: normal;
	font-weight: 700;
	color: color('corporate');
	line-height: 1.4;
}

@mixin u-h2 {
	@include heading-link;
	@include u-webfont('sans');
	font-size: $context--font-size-xl;
	font-style: normal;
	font-weight: 700;
	color: color('corporate');
	line-height: 1.4;
	margin-bottom: 0.5em;
}

@mixin u-h3 {
	@include heading-link;
	@include u-webfont('sans');
	font-size: $context--font-size-md;
	color: color('corporate');
	font-style: normal;
	line-height: 1.4;
	margin-bottom: 0.7em;
}

@mixin u-h4 {
	@include heading-link;
	@include u-webfont('sans');
	font-size: $context--font-size-md;
	color: color('corporate');
	font-style: italic;
	font-weight: 700;
	line-height: 1.4;
	margin-bottom: 0.7em;
}
