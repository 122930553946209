$context--font-family-serif: Georgia, Palatino, serif;
$context--font-family-serif-webfont: 'Noto Serif';
$context--font-family-sans-webfont: 'Europa', 'Trebuchet MS';

$context--font-size-xxs: 10px;
$context--font-size-xs: 12px;
$context--font-size-sm: 14px;
$context--font-size-md: 18px;
$context--font-size-lg: 24px;
$context--font-size-xl: 26px;
$context--font-size-xxl: 28px;

$context--font-size-furniture: $context--font-size-sm;
$context--font-size-interface: $context--font-size-sm;

$context--font-weight-normal: 400;
$context--font-weight-bold: 700;

$context--font-weight: (
	light: 100,
	normal: 400,
	bold: 700,
);

$context--line-height-base: 1.61803398875; // golden ratio
$context--line-height-tight: 1.4;
