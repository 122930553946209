@use "sass:color";

$sidebar-nav-highlight-color: color.scale(color('accent'), $lightness: 73%);
$sidebar-nav-strong-highlight-color: color.scale(color('accent'), $lightness: 50%);

.c-sidebar-nav {
	font-size: inherit;
	position: relative;

	&.is-fixed {
		position: fixed;
		top: 0;
		z-index: 1;
	}
}

.c-sidebar-nav__title {
	font-family: $context--font-family-sans;
	font-size: inherit;
	font-weight: 400;
	margin-bottom: 0;
	display: none;

	.js & {
		display: block;

		@include media-query('md') {
			display: none;
		}
	}
}

.c-sidebar-nav__toggle {
	text-decoration: underline;

	&::after {
		@include triangle($direction: bottom, $size-x: 10px, $size-y: 4px, $color: color('corporate'));
		margin-left: spacing(8);
	}

	&.is-shown {
		&::after {
			transform: rotate(180deg);
		}
	}
}

.c-sidebar-nav__menu {
	@include hide-toggle;
	list-style: none;
	margin: spacing(8) 0 0;
	padding: 0;
	position: absolute;
	z-index: 5;
	top: 100%;
	background-color: #fff;
	border: 1px solid greyscale(80);
	font-weight: 400;
	line-height: 1.4;
	box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .1);

	@include media-query('xl') {
		display: block !important;
		position: static;
		margin-top: 0;
		border: none;
		background: transparent;
		box-shadow: none;
	}
}

.c-sidebar-nav__sub-menu {
	list-style: none;
	margin: 0;
	padding: 0;

	.c-sidebar-nav__menu-item--is-shown > & {
		background-color: $sidebar-nav-highlight-color;
	}

	.c-sidebar-nav__menu-link {
		padding-left: spacing(24);

		&:hover {
			background-color: $sidebar-nav-strong-highlight-color;
		}

		.c-sidebar-nav__sub-menu & {
			padding-left: spacing(32);
		}
	}
}

.c-sidebar-nav__menu-link {
	display: block;
	padding: 6px 20px; // Exception to spacing rule

	&--ext-link::after {
		content: '';
		display: inline-block;
		vertical-align: baseline;
		width: 10px;
		height: 10px;
		background: url('../../images/ext-link.svg') no-repeat center;
	}

	&:hover {
		background-color: $sidebar-nav-highlight-color;
	}

	.c-sidebar-nav__menu-item--has-children > & {
		align-items: baseline;
		display: flex;
		padding-left: spacing(4);

		.c-sidebar-nav__sub-menu & {
			padding-left: 20px; // Exception to spacing rule
		}
	}

	.c-sidebar-nav__menu-item--is-shown > &,
	.c-sidebar-nav__sub-menu-item--is-shown > & {
		background-color: $sidebar-nav-highlight-color;
		font-weight: bold;
	}

	.c-sidebar-nav__sub-menu-item--is-shown > & {
		background-color: $sidebar-nav-strong-highlight-color;
	}
}

.c-sidebar-nav__submenu-icon {
	fill: color('corporate');
	flex: 0 0 auto;
	margin-right: spacing(4);
}
