.c-social-links {
	@include u-clearfix;
	@include u-list-reset;
	margin-top: 0 - spacing(8);
}

.c-social-links__item {
	float: left;
	line-height: 0;
	margin-top: spacing(8);
	margin-right: spacing(8);

	&:last-child {
		margin-right: 0;
	}

	button {
		background: none;
		border: 0;
		margin: 0;
		padding: 0;
	}
}

.c-social-links__icon {
	fill: color('corporate');
}
