@use "sass:math";

$nav-font-size: 1.4rem;
$nav-padding: 0.4em 1em;
$nav-line-height: math.div(20, 14);
$nav-tabs-border-color: lightgrey;
$nav-tabs-item-padding: 0.7em 1.8em;
$nav-tabs-item-border-color: lightgrey;
$nav-tabs-item-border-color-active: darkgrey;
$nav-tabs-item-background-color: lightgrey;
$nav-tabs-item-background-color-active: white;
