.c-impact-factor {
	color: $c-impact-factor-color; // mid grey
	font-weight: 700;
	margin: 0;
	line-height: $c-impact-factor-line-height;
	font-size: $c-impact-factor-font-size;
	text-align: right;
}

.c-impact-factor__value {
	color: $c-impact-factor-value-color;
}
