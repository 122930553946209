.c-form-field {
	margin-bottom: $form-field-spacing;
}

.c-form-field__label {
	font-size: $form-input-font-size;
	display: block;
	color: $form-label-color;
	margin-bottom: $form-label-spacing;
}

.c-form-field__input,
.c-form-field__select {
	font-size: $form-input-font-size;
	border: $form-input-border;
	border-radius: $form-input-border-radius;
	vertical-align: middle;
	line-height: $form-input-line-height;
}

.c-form-field__input {
	box-shadow: $form-input-box-shadow;
	padding: $form-input-padding;
	width: 100%;

	&--auto {
		width: auto;
	}
}

.c-form-field__select {
	height: $form-select-height;

	&--auto {
		height: auto;
	}
}

.c-form-field__error {
	font-size: $context--font-size-sm;
	color: color('error');
	display: block;
}

.c-form-field--inline {
	display: flex;

	.c-form-field__label,
	.c-form-field__input,
	.c-form-field__select {
		margin-bottom: 0;
		margin-right: 0.75em;

		&:last-child {
			margin-right: 0;
		}
	}

	.c-form-field__input {
		flex-grow: 1;
		width: auto;
	}
}

.c-form-field--option {
	input[type="checkbox"],
	input[type="radio"] {
		margin-right: 0.5em;
		transform: translateY(1px);
	}

	.c-form-field__label {
		display: flex;
		align-items: baseline;
	}
}

.c-form-group {
	margin-bottom: $form-field-spacing;

	.c-form-field {
		margin-bottom: 0;
	}
}

// TODO: Checkbox modifier / radio
// .c-form-field--check {

// }
