.c-anchored-heading {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	border-bottom: 1px solid greyscale(80);
	padding-bottom: spacing(4);
	margin-bottom: spacing(16);

	&--collapsible {
		@include media-query('md', 'max') {
			position: relative;
			border-bottom: 0;
			margin: 0 (0 - $global-layout-gutter-small) spacing(8);
			background-color: greyscale(95);
			padding: spacing(8) $global-layout-gutter-small;
			cursor: pointer;

			&::after {
				@include triangle($direction: bottom, $size-x: 10px, $size-y: 4px, $color: color('corporate'));
				position: absolute;
				right: 20px;
				top: 50%;
			}

			&.is-shown {
				margin-bottom: spacing(16);

			}

			&.is-shown::after {
				transform: rotate(180deg);
			}
		}
	}
}

.c-anchored-heading__title {
	@include u-h2;
	margin-bottom: 0;
	line-height: 1.4;

	.c-anchored-heading--collapsible & {
		@include media-query('md', 'max') {
			font-size: $context--font-size-lg;
		}
	}
}

.c-anchored-heading__helper {
	font-size: $context--font-size-sm;
	font-family: $context--font-family-sans;
	align-self: flex-end;
	line-height: 1.4;
	transform: translateY(-3px);
	white-space: nowrap;

	.c-anchored-heading--collapsible & {
		display: none;

		@include media-query('md') {
			display: block;
		}
	}
}
